import "./RenewAuthorization.css";

import * as CommonFns from "../../../../../common";
import * as toastMessages from "../../../../../toastMessages";

import {
	Box,
	Button,
	ButtonGroup,
	Checkbox,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spacer,
	Spinner,
	Table,
	Tbody,
	Td,
	Text,
	Tfoot,
	Tr,
	chakra,
	useBreakpointValue,
	useDisclosure,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { Grid, _ } from "gridjs-react";
import React, { useContext, useEffect, useState } from "react";
import { axiosMyProperty, axiosMyPropertyAuth } from "../../../../../apis";

import { AuthContext } from "../../../../../contexts/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LegalText from "../../../../Global/LegalText/LegalText";
import Markdown from "../../../../Global/Markdown/Markdown";
import RenewDetails from "./RenewDetails/RenewDetails";
import { h } from "gridjs";
import { useToast } from "@chakra-ui/react";

const RenewAuthorization = ({ openRenewAuthModal, setOpenRenewAuthModal, refreshDataToggle, setRefreshDataToggle }) => {
	const [renewalDetails, setRenewalDetails] = useState({});
	const [expiredProperties, setExpiredProperties] = useState([]);
	const [renewProperties, setRenewProperties] = useState([]);
	const [isRenewAuthRepFlag, setIsRenewAuthRepFlag] = useState(false);
	const [isAgreeTermsFlag, setIsAgreeTermsFlag] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isOpenRepAuthTerms, onOpen: onOpenRepAuthTerms, onClose: onCloseRepAuthTerms } = useDisclosure();
	const [isStepOne, setIsStepOne] = useState(true);
	const [isStepTwo, setIsStepTwo] = useState(false);
	const [isStepThree, setIsStepThree] = useState(false);
	const [showRenewPropertiesError, setShowRenewPropertiesError] = useState(false);
	const [renewPropertiesError, setRenewPropertiesError] = useState("");
	const { authDetails } = useContext(AuthContext);
	const [grantorName, setGrantorName] = useState("");
	const [isGrantorNameError, setIsGrantorNameError] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const toast = useToast();
	const renewPropertiesToastErrorId = "renew-properties-toast-error";
	const renewPropertiesToastSuccessId = "renew-properties-toast-success";

	useEffect(() => {
		if (openRenewAuthModal) {
			getExpiredProperties();
			setOpenRenewAuthModal(false);
		}
	}, [openRenewAuthModal]);

	const getExpiredProperties = () => {
		let url = `shareYourInfo/expired`;

		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;
				if (returnObj.retVal === "true") {
					setRenewalDetails(returnObj);
					if (returnObj.emailAgentCol !== null) {
						setExpiredProperties(returnObj.emailAgentCol);
						onOpen();
					}
				} else {
					if (!toast.isActive(renewPropertiesToastErrorId)) {
						toast(toastMessages.getToastError(renewPropertiesToastErrorId));
					}
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(renewPropertiesToastErrorId)) {
					toast(toastMessages.getToastError(renewPropertiesToastErrorId));
				}
			});
	};

	const closeModal = () => {
		setIsStepOne(true);
		setIsStepTwo(false);
		setIsStepThree(false);
		setRenewPropertiesError("");
		setShowRenewPropertiesError(false);
		setRenewProperties([]);
		setIsRenewAuthRepFlag(false);
		setIsAgreeTermsFlag(false);
		setGrantorName("");
		setIsGrantorNameError(false);
		onClose();
	};

	const getModalHeader = () => {
		let header = "";

		if (expiredProperties?.length > 0) {
			if (isStepOne) {
				header = "Shared Information Renewal";
			} else if (isStepTwo) {
				header = "Renewal: Representative Access";
			} else if (isStepThree) {
				header = "Renewal: Confirmation";
			}
		}

		return header;
	};

	const handleRepAuthTermsModalOpen = (e) => {
		e.preventDefault();
		onOpenRepAuthTerms();
	};

	const handleBackClick = () => {
		setShowRenewPropertiesError(false);
		if (isStepTwo) {
			setIsStepTwo(false);
			setIsStepOne(true);
		} else if (isStepThree) {
			setIsStepThree(false);

			//if at least one property is a representative then go to step 2, otherwise go to step 1 */
			if (renewProperties.some((x) => x.accountsCol.some((y) => y.representativeFlag === "Y"))) {
				setIsStepTwo(true);
			} else {
				setIsStepTwo(false);
				setIsStepOne(true);
			}
		}
	};
	const handleNextClick = () => {
		setShowRenewPropertiesError(false);
		if (isStepOne) {
			if (renewProperties?.length === 0) {
				setRenewPropertiesError("Please select at least one property before continuing.");
				setShowRenewPropertiesError(true);
			} else {
				setIsStepOne(false);

				//if at least one property is a representative, if so, go to step 2, otherwise, go to step 3
				if (renewProperties.some((x) => x.accountsCol.some((y) => y.representativeFlag === "Y"))) {
					setIsStepTwo(true);
				} else {
					setIsStepTwo(false);
					setIsStepThree(true);
				}
			}
		} else if (isStepTwo) {
			if (isRenewAuthRepFlag) {
				if (grantorName.trim() === "") {
					setIsGrantorNameError(true);
				} else {
					setIsGrantorNameError(false);
					setIsStepTwo(false);
					setIsStepThree(true);
				}
			} else {
				setRenewPropertiesError("Please select Yes to proceed.");
				setShowRenewPropertiesError(true);
			}
		} else if (isStepThree) {
			if (isAgreeTermsFlag) {
				submitRenewal();
			} else {
				setRenewPropertiesError("You must accept the Representative Authorization Terms.");
				setShowRenewPropertiesError(true);
			}
		}
	};

	const handleClearClick = () => {
		if (isStepOne) {
			setRenewProperties([]);
			setIsRenewAuthRepFlag(false);
		} else if (isStepTwo) {
			setIsRenewAuthRepFlag(false);
			setGrantorName("");
			setIsGrantorNameError(false);
		}
		setShowRenewPropertiesError(false);
	};

	const submitRenewal = () => {
		let url = "shareYourInfo/renew";

		let payload = {};

		payload.accountsCol = [];

		renewProperties.forEach((x) => {
			x.accountsCol.forEach((y) => {
				payload.accountsCol.push({
					accId: y.accId,
					wuaId: y.wuaId,
					wuaIdAuth: y.wuaIdAuth,
					renewedViewerExpYear: y.renewedViewerExpYear,
					representativeFlag: isRenewAuthRepFlag ? "Y" : "N",
					grantorName: grantorName,
					emailAddress: x.emailAddress,
				});
			});
		});

		return axiosMyPropertyAuth
			.post(url, payload)
			.then((resp) => {
				let returnObj = resp.data;
				//if success, show toast
				if (!toast.isActive(renewPropertiesToastSuccessId)) {
					toast(
						toastMessages.getToastSuccess(
							renewPropertiesToastSuccessId,
							"You've successfully renewed access to view yourinformation."
						)
					);
				}
				setRefreshDataToggle(!refreshDataToggle);
				setIsSubmitting(false);
				closeModal();
				return returnObj;
			})
			.catch((error) => {
				// error
				setIsSubmitting(false);
				if (!toast.isActive(renewPropertiesToastErrorId)) {
					toast(toastMessages.getToastError(renewPropertiesToastErrorId));
				}
			});
	};

	return (
		<>
			<Modal
				size={"4xl"}
				closeOnOverlayClick={false}
				closeOnEsc={false}
				isOpen={isOpen}
				onClose={closeModal}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader className={"modal-header"}>{getModalHeader()}</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<Box my={3}>
							{isStepOne ? (
								<>
									<Box my={3}>
										<Box
											pb={3}
											fontSize={"xl"}>
											<Markdown text={renewalDetails.sharedInfoRenewalMsg}></Markdown>
										</Box>
										<Box
											mt={3}
											mb={5}>
											<Flex>
												<Text fontWeight={"bold"}>Renewed access expiry date:</Text>
												<Text ml={2}>
													{" "}
													{CommonFns.FormatDate(renewalDetails.renewedAccessExpiryDate)}
												</Text>
											</Flex>
										</Box>
										<Box>
											<RenewDetails
												displayProperties={expiredProperties}
												selectedProperties={renewProperties}
												setSelectedProperties={setRenewProperties}
												isReadOnly={false}
											/>
										</Box>
									</Box>
								</>
							) : null}

							{isStepTwo ? (
								<>
									<Box>
										<Box
											mb={3}
											fontSize={"xl"}>
											<Markdown text={renewalDetails?.sharedInfoRenewalMsgHdr}></Markdown>
										</Box>
										<Box mb={8}>
											<Markdown text={renewalDetails?.sharedInfoRenewalMsgTxt}></Markdown>
										</Box>
										<Checkbox
											onChange={(e) => {
												setIsRenewAuthRepFlag(e.target.checked);
												setRenewPropertiesError("");
												setIsGrantorNameError(false);
											}}
											className='renew-properties form-check-input'
											isChecked={isRenewAuthRepFlag}>
											<chakra.span fontWeight={700}>
												Yes, renew authorized representative access.{" "}
											</chakra.span>
											<chakra.span
												color={"#00a5d4"}
												fontStyle={"italic"}>
												(required to proceed)
											</chakra.span>
										</Checkbox>
									</Box>
									{isRenewAuthRepFlag ? (
										<>
											<Box mt={10}>
												<Box>
													<Markdown text={renewalDetails?.shareYourInfoRepName}></Markdown>
												</Box>
												<Flex
													direction={["column", "column", "row"]}
													w='100%'>
													<Box>
														<FormLabel
															htmlFor='grantorName'
															className='form-label-fixed'>
															Your name{" "}
															<span
																colorScheme={"red"}
																className={"text-error pos"}>
																*
															</span>
														</FormLabel>
													</Box>
													<Box pr={2}>
														<Input
															id='grantorName'
															type='grantorName'
															onChange={(e) => setGrantorName(e.target.value)}
															placeholder=''
															autoComplete='off'
															minW={["", "", 350]}
															value={grantorName}
														/>
													</Box>
													{isGrantorNameError ? (
														<>
															<Box>
																<Text
																	colorScheme={"red"}
																	className={"text-error pos"}>
																	Please enter your name before continuing
																</Text>
															</Box>
														</>
													) : null}
												</Flex>
											</Box>
										</>
									) : null}
								</>
							) : null}
							{isStepThree ? (
								<>
									<Box>
										<Box
											mb={3}
											fontSize={"xl"}>
											<Markdown text={renewalDetails?.sharedInfoRenewalConfHdr}></Markdown>
										</Box>
										<Box my={3}>
											<Flex>
												<Text fontWeight={700}>Renewed access expiry date:</Text>
												<Text ml={2}>
													{" "}
													{CommonFns.FormatDate(renewalDetails.renewedAccessExpiryDate)}
												</Text>
											</Flex>
										</Box>
										<Box>
											<Markdown text={renewalDetails?.sharedInfoRenewalConfTxt}></Markdown>
										</Box>

										<Box mt={5}>
											<RenewDetails
												displayProperties={renewProperties}
												isReadOnly={true}
											/>
										</Box>
										<Box my={5}>
											<Checkbox
												size={"lg"}
												onChange={(e) => {
													setIsAgreeTermsFlag(e.target.checked);
												}}
												className='renew-properties form-check-input'
												isChecked={isAgreeTermsFlag}>
												<chakra.span
													fontWeight={700}
													fontSize={".85em"}>
													I've read and agree to the{" "}
													<Link
														className='legal-link'
														onClick={(e) => handleRepAuthTermsModalOpen(e)}>
														Representative Authorization Terms
													</Link>
													{". "}
													<chakra.span
														color={"#00a5d4"}
														fontStyle={"italic"}>
														(mandatory)
													</chakra.span>
												</chakra.span>
											</Checkbox>
										</Box>
									</Box>
								</>
							) : null}
							{showRenewPropertiesError ? (
								<>
									<Box
										colorScheme={"red"}
										className={"text-error pos"}
										mt={2}>
										{renewPropertiesError}
									</Box>
								</>
							) : null}
						</Box>
					</ModalBody>
					<ModalFooter
						className={"modal-footer"}
						w={"100%"}
						justifyContent={"justify"}>
						<Box w={"inherit"}>
							<Flex
								direction={["column", "column", "row"]}
								w={["100%", "100%", ""]}>
								{isStepTwo || isStepThree ? (
									<>
										<Button
											colorScheme='lightBlue'
											minW={["100%", "100%", 100]}
											onClick={() => handleBackClick()}>
											BACK
										</Button>
									</>
								) : null}
								<Spacer />
								{isStepOne || isStepTwo ? (
									<>
										<Button
											colorScheme='lightBlue'
											onClick={() => handleNextClick()}
											minW={["100%", "100%", 100]}
											mr={[0, 0, 2]}
											mt={[2, 2, 0]}>
											NEXT
										</Button>

										<Button
											colorScheme='grey'
											type='reset'
											variant={"outline"}
											mt={[2, 2, 0]}
											minW={["100%", "100%", 100]}
											onClick={(e) => handleClearClick(e)}>
											CLEAR
										</Button>
									</>
								) : null}
								{isStepThree ? (
									<>
										<Button
											colorScheme='lightBlue'
											onClick={() => {
												setIsSubmitting(true);
												submitRenewal();
											}}
											isDisabled={authDetails.isAdmin || isSubmitting}
											minW={["100%", "100%", 100]}
											mr={[0, 0, 2]}
											mt={[2, 2, 0]}>
											{isSubmitting ? (
												<>
													<Spinner
														size='sm'
														mr={2}></Spinner>
												</>
											) : null}
											RENEW ACCESS
										</Button>
										<Button
											colorScheme='grey'
											variant={"outline"}
											mt={[2, 2, 0]}
											minW={["100%", "100%", 100]}
											onClick={() => closeModal()}>
											Cancel
										</Button>{" "}
									</>
								) : null}
							</Flex>
						</Box>
					</ModalFooter>
				</ModalContent>
			</Modal>

			{/* REPRESENTATIVE AUTHORIZAATION TERMS */}
			<Modal
				closeOnOverlayClick={true}
				isOpen={isOpenRepAuthTerms}
				onClose={onCloseRepAuthTerms}>
				<ModalOverlay />
				<ModalContent maxW={"57rem"}>
					<ModalHeader className={"modal-header"}>Representative Authorization - Terms</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<LegalText displayMessageTypes={["AGENT_REPRESENTATIVE_AUTHORIZATION_TERMS"]}></LegalText>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						{/* <Button colorScheme='blue' size={"sm"} onClick={(e) => handleRepAuthTermsModalClose(e)}>
							Close
						</Button> */}
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
export default RenewAuthorization;
