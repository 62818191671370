import * as CommonFns from "../../../common";
import * as toastMessages from "../../../toastMessages";

import {
	Badge,
	Box,
	Button,
	Flex,
	Spacer,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	chakra,
	useMediaQuery,
	useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { AuditContext } from "../../../contexts/AuditContext";
import { AuthContext } from "../../../contexts/AuthContext";
import ListMail from "./ListMail/ListMail";
import { axiosMyPropertyAuth } from "../../../apis";
import { useNavigate } from "react-router-dom";

const MyMail = ({ propertyCount, addRemovePropertyToggle, handleAddPropertyLink, isPropertyView }) => {
	const [isLargerThan480] = useMediaQuery("(min-width: 480px)");
	const [tabIndex, setTabIndex] = useState(0);

	const handleTabsChange = (index) => {
		setTabIndex(index);
	};

	const { resetAuthAccountNumber } = useContext(AuthContext);
	const { auditActivity } = useContext(AuditContext);
	const toast = useToast();
	const profileMailErrorId = "profile-mail-toast-error";

	const [ownerMessage, setOwnerMessage] = useState("");
	const [viewerMessage, setViewerMessage] = useState("");

	const [ownerMail, setOwnerMail] = useState(null);
	const [ownerMailUnreadCount, setOwnerMailUnreadCount] = useState(0);

	const [sharedMail, setSharedeMail] = useState(null);
	const [sharedMailCount, setSharedeMailCount] = useState(0);

	const [ipAddress, setIpAddress] = useState("");

	let navigate = useNavigate();

	useEffect(() => {
		CommonFns.getIP().then((ipAddress) => setIpAddress(ipAddress));
		resetAuthAccountNumber();
		getMail();
	}, []);

	useEffect(() => {
		getMail();
	}, [addRemovePropertyToggle]);

	const getMail = () => {
		let url = `mail`;
		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				let returnObj = resp.data;

				if (returnObj.retVal === "true") {
					// profile (owner) mail
					setOwnerMail(returnObj.webMymailOwnerCol);
					setOwnerMessage(returnObj.webMymailOwnerMsg);
					if (returnObj.webMymailOwnerCol !== null) {
						setOwnerMailUnreadCount(returnObj.webMymailOwnerCol.filter((x) => x.status === "New").length);
					} else {
						setOwnerMailUnreadCount(0);
					}

					// shared (viewer) mail
					setSharedeMail(returnObj.webMymailViewerCol);
					setViewerMessage(returnObj.webMymailViewerMsg);
					setSharedeMailCount(
						returnObj.webMymailViewerCol?.length > 0 ? returnObj.webMymailViewerCol.length : 0
					);
				} else {
					setOwnerMessage("");
					setViewerMessage("");

					setOwnerMail(null);
					setSharedeMail(null);

					setOwnerMailUnreadCount(0);
				}
			})
			.catch((error) => {
				//error
				if (!toast.isActive(profileMailErrorId)) {
					toast(toastMessages.getToastError(profileMailErrorId, error.message));
				}
			});
	};

	const handleMyMailViewAll = (e) => {
		e.preventDefault();

		// audit
		auditActivity("Clicked View All - My Mail");

		// redirect
		navigate(`/auth/my-mail`, { replace: true });
	};

	const handleGetProfileMail = () => {
		getMail();
	};

	return (
		<React.Fragment>
			{!isPropertyView && sharedMailCount > 0 ? (
				<Tabs
					variant='unstyled'
					index={tabIndex}
					onChange={handleTabsChange}>
					<TabList
						mb='1em'
						className='tab-list'>
						{/* OWNER */}
						<Tab
							className='tab'
							_selected={{ color: "white", bg: "blue.500" }}
							_hover={{ color: "white", bg: "blue.500" }}>
							<chakra.span
								fontWeight={700}
								fontSize={!isLargerThan480 ? ".9em" : "1em"}>
								MyMail{" "}
							</chakra.span>
							{ownerMailUnreadCount > 0 ? (
								<Badge
									ml={2}
									bg={tabIndex === 0 ? "#e9eaea" : "#004060"}
									color={tabIndex === 0 ? "#004060" : "#e9eaea"}>
									<Text fontSize={"1.3em"}>{ownerMailUnreadCount}</Text>
								</Badge>
							) : null}
						</Tab>
						{/* VIEWER */}
						<Tab
							className='tab'
							_selected={{ color: "white", bg: "blue.500" }}
							_hover={{ color: "white", bg: "blue.500" }}>
							<chakra.span
								fontWeight={700}
								fontSize={!isLargerThan480 ? ".9em" : "1em"}>
								Shared Mail
							</chakra.span>
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel className='tab-panel'>
							{/* OWNER */}
							<ListMail
								isPropertyView={isPropertyView}
								propertyCount={propertyCount}
								mailMessage={ownerMessage}
								items={ownerMail}
								onGetProfileMail={handleGetProfileMail}
								onAddPropertyLink={handleAddPropertyLink}
								ipAddress={ipAddress}
								isOwnerMail={true}></ListMail>
						</TabPanel>
						<TabPanel className='tab-panel'>
							{/* VIEWER */}
							<ListMail
								isPropertyView={false}
								propertyCount={1}
								mailMessage={viewerMessage}
								items={sharedMail}
								onGetProfileMail={handleGetProfileMail}
								onAddPropertyLink={handleAddPropertyLink}
								ipAddress={ipAddress}
								isOwnerMail={false}></ListMail>
						</TabPanel>
					</TabPanels>
				</Tabs>
			) : (
				<>
					<Box mb={!isPropertyView ? 2 : 0}>
						<Flex>
							<Box>
								<Flex>
									<Box>
										<chakra.span fontSize={"1.5em"}>My Mail</chakra.span>
									</Box>
									{ownerMailUnreadCount > 0 ? (
										<Box
											ml={1.5}
											mt={1}>
											<Badge
												bg={"#004060"}
												color={"#e9eaea"}>
												<Text>{ownerMailUnreadCount}</Text>
											</Badge>
										</Box>
									) : null}
								</Flex>
							</Box>
							<Spacer></Spacer>
							{isPropertyView ? (
								<Box>
									<Button
										colorScheme='blue'
										fontSize={"0.95em"}
										onClick={(e) => {
											handleMyMailViewAll(e);
										}}>
										VIEW ALL
									</Button>
								</Box>
							) : null}
						</Flex>
					</Box>
					<ListMail
						isPropertyView={isPropertyView}
						propertyCount={propertyCount}
						mailMessage={ownerMessage}
						items={ownerMail}
						onGetProfileMail={handleGetProfileMail}
						onAddPropertyLink={handleAddPropertyLink}
						ipAddress={ipAddress}
						isOwnerMail={true}></ListMail>
				</>
			)}
		</React.Fragment>
	);
};

export default MyMail;
