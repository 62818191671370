import "./ShareYourInformation.css";

import * as CommonFns from "../../../../common";
import * as toastMessages from "../../../../toastMessages";

import {
	Box,
	Button,
	Center,
	Flex,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Skeleton,
	chakra,
	useDisclosure,
} from "@chakra-ui/react";
import { Grid, _ } from "gridjs-react";
import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Markdown from "../../../Global/Markdown/Markdown";
import NewAuthorization from "./NewAuthorization/NewAuthorization";
import RenewAuthorization from "./RenewAuthorization/RenewAuthorization";
import { axiosMyPropertyAuth } from "../../../../apis";
import { h } from "gridjs";
import { useToast } from "@chakra-ui/react";

const ShareYourInformation = ({}) => {
	const [shareYourInfoDetails, setShareYourInfoDetails] = useState([]);
	const [authorizedProperties, setAuthorizedProperties] = useState();
	const [openNewAuthModal, setOpenNewAuthModal] = useState(false);
	const [openRenewAuthModal, setOpenRenewAuthModal] = useState(false);
	const [refreshDataToggle, setRefreshDataToggle] = useState(false);
	const [deleteAuthorizedProperty, setDeleteAuthorizedProperty] = useState({});
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: isOpenRenewalNotAllowed,
		onOpen: onOpenRenewalNotAllowed,
		onClose: onCloseRenewalNotAllowed,
	} = useDisclosure();

	const [pageLimit] = useState(10);

	const toast = useToast();
	const shareYourInfoToastErrorId = "shareYourInfo-toast-error";

	useEffect(() => {
		setAuthorizedProperties();
		loadData();
	}, [refreshDataToggle]);

	const loadData = () => {
		let url = `shareYourInfo`;

		axiosMyPropertyAuth
			.get(url)
			.then((resp) => {
				setShareYourInfoDetails(resp.data);
				if (resp.data.accountsCol !== null) {
					setAuthorizedProperties(resp.data.accountsCol);
				} else {
					setAuthorizedProperties([]);
				}
			})
			.catch((error) => {
				// error
				if (!toast.isActive(shareYourInfoToastErrorId)) {
					toast(toastMessages.getToastError(shareYourInfoToastErrorId));
				}
			});
	};

	// Grid columns
	const gridColumns = [
		{
			id: "propertyAddress",
			name: "Address",
			formatter: (cell, row) => {
				return h(
					"div",
					row.cells[5].data === "Expiring" || row.cells[5].data === "Expired"
						? {
								style: { "font-weight": "700" },
								onClick: (e) => {
									e.preventDefault();
									row.cells[8].data === "Y" ? setOpenRenewAuthModal(true) : onOpenRenewalNotAllowed();
								},
						  }
						: {
								style: { "font-weight": "700" },
						  },
					row.cells[5].data === "Expiring" || row.cells[5].data === "Expired"
						? _(
								<Link
									className='grid-link view'
									title='Renew authorization access'
									fontWeight={700}>
									{cell}
								</Link>
						  )
						: _(cell)
				);
			},
			width: authorizedProperties?.length > 0 ? "30%" : "",
		},
		{
			id: "accId",
			name: "Account",
			formatter: (cell) => {
				return h(
					"div",
					{
						style: { "font-weight": "700" },
					},
					cell
				);
			},
			//width: "10%",
		},
		{
			id: "emailAddress",
			name: "Authorized Email",
			formatter: (cell) => {
				return h(
					"div",
					{
						style: { "font-weight": "700" },
					},
					cell
				);
			},
			width: "18%",
		},
		{
			id: "representativeFlag",
			name: "Representative Authorization",
			formatter: (cell) => {
				return h(
					"div",
					{
						style: { "font-weight": "700", "text-align": "center" },
					},
					cell === "Y" ? "Yes" : "No"
				);
			},
			//width: "10%",
		},
		{
			id: "viewerExpDate",
			name: "Expiry Date",
			formatter: (cell) => {
				return h(
					"div",
					{
						style: { "font-weight": "700" },
					},
					CommonFns.FormatDate(cell, "MMM DD, YYYY")
				);
			},
			width: "13%",
		},
		{
			id: "authStatus",
			formatter: (cell, row) => {
				return h(
					"div",
					{
						className:
							cell === "Pending" || cell === "Reviewing" || cell === "Expiring" || cell === "Expired"
								? "share-your-info-grid-badge"
								: "",
						title:
							cell === "Pending"
								? "Waiting for the authorized person to accept your invitation."
								: cell === "Reviewing"
								? "Your request is being reviewed by the Assessment Team."
								: cell === "Expiring"
								? row.cells[8].data === "Y"
									? "This authorization to view your information for this property will expire at the end of the year. Click the address to renew this authorization."
									: "This authorization to view your information for this property will expire at the end of the year."
								: cell === "Expired"
								? row.cells[8].data === "Y"
									? "This authorization to view your information has expired. Click the address to renew this authorization."
									: "This authorization to view your information has expired. The owner who granted this access can renew it, or you can create a new authorization."
								: "",
					},
					_(<chakra.span>{cell}</chakra.span>)
				);
			},
			width: "8%",
		},
		{
			id: "delete",
			sort: false,
			formatter: (cell, row) => {
				return h(
					"button",
					{
						className: "grid-button red xl",
						onClick: (e) => {
							e.preventDefault();
							confirmDeleteAuthorizedProperty(row.cells[2].data, row.cells[7].data, row.cells[5].data);
						},
						title: "Remove authorized access to property",
					},
					_(
						<FontAwesomeIcon
							fontSize={"1.15em"}
							icon='fa-solid fa-square-xmark'
						/>
					)
				);
			},
			width: "3%",
		},
		{
			id: "wuaId",
			formatter: (cell) => {
				return h("div", {}, cell);
			},
			hidden: true,
		},
		{
			id: "grantedByMe",
			formatter: (cell) => {
				return h("div", {}, cell);
			},
			hidden: true,
		},
	];

	const gridClass = {
		table: "share-your-info-grid",
	};

	// Grid paging
	const gridPagination = {
		enabled: authorizedProperties?.length > pageLimit, //gridItems !== undefined ? (isPaging ? true : false) : false,
		page: 0, //gridItems !== undefined ? (gridItems.length > pageLimit ? currentPage : 0) : 0,
		limit: pageLimit,
		resetPageOnUpdate: true,
	};

	const openNewAuthorizationModal = (e) => {
		setOpenNewAuthModal(true);
	};

	const confirmDeleteAuthorizedProperty = (email, wuaId, authStatus) => {
		setDeleteAuthorizedProperty({ emailAddress: email, wuaId: wuaId, authStatus: authStatus });
		onOpen();
	};

	const handleDeleteAuthorizedProperty = () => {
		let url = `shareYourInfo/delete`;

		axiosMyPropertyAuth
			.post(url, deleteAuthorizedProperty)
			.then((resp) => {
				let returnObj = resp.data;
				onClose();
				if (returnObj.retVal === "true") {
					toast({
						title: "Success!",
						description: "Property access has been removed.",
						status: "success",
						position: "top-right",
						duration: 3000,
						isClosable: true,
					});
				} else {
					if (!toast.isActive(shareYourInfoToastErrorId)) {
						toast(toastMessages.getToastError(shareYourInfoToastErrorId));
					}
				}

				//refresh data after calling delete api
				setRefreshDataToggle(!refreshDataToggle);
			})
			.catch((error) => {
				// error
				if (!toast.isActive(shareYourInfoToastErrorId)) {
					toast(toastMessages.getToastError(shareYourInfoToastErrorId));
				}
			});
	};

	const handleCancelDeleteAuthorizedProperty = () => {
		//clear delete object
		setDeleteAuthorizedProperty({});
		//close modal
		onClose();
	};

	return (
		<>
			<Box maxW={1050}>
				<Box
					mb={3}
					mt={3}>
					<chakra.span fontSize={"xl"}>
						<Markdown text={shareYourInfoDetails?.shareYourInfoHdr}></Markdown>
					</chakra.span>
				</Box>
				<Box pb={2}>{shareYourInfoDetails?.shareYourInfoTxt}</Box>
			</Box>
			<Box
				pb={2}
				maxW={1225}>
				{authorizedProperties !== undefined ? (
					<>
						<Grid
							data={authorizedProperties}
							columns={gridColumns}
							className={gridClass}
							pagination={gridPagination}
							search={false}
							sort={false}
							language={{ noRecordsFound: shareYourInfoDetails?.shareYourInfoNoRecord }}></Grid>
					</>
				) : (
					<>
						<Skeleton
							height={10}
							width={"500px"}
							startColor='#e2e2e2'
							endColor='#bbbbbb'></Skeleton>
						<Skeleton
							mt={4}
							height={100}
							startColor='#e2e2e2'
							endColor='#bbbbbb'></Skeleton>
					</>
				)}
			</Box>

			<Box>
				<Button
					colorScheme='lightBlue'
					//size={'md'}
					//fontSize={'md'}
					//width={20}
					onClick={(e) => {
						openNewAuthorizationModal(e);
					}}>
					Create new authorization
				</Button>
			</Box>

			<NewAuthorization
				shareYourInfoDetails={shareYourInfoDetails}
				openNewAuthModal={openNewAuthModal}
				setOpenNewAuthModal={setOpenNewAuthModal}
				refreshDataToggle={refreshDataToggle}
				setRefreshDataToggle={setRefreshDataToggle}></NewAuthorization>

			<RenewAuthorization
				openRenewAuthModal={openRenewAuthModal}
				setOpenRenewAuthModal={setOpenRenewAuthModal}
				refreshDataToggle={refreshDataToggle}
				setRefreshDataToggle={setRefreshDataToggle}
			/>

			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpen}
				onClose={() => handleCancelDeleteAuthorizedProperty()}>
				<ModalOverlay />
				<ModalContent maxW={"37rem"}>
					<ModalHeader className={"modal-header"}>Stop Sharing Your Information</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<Box
							mt={2}
							mb={2}>
							This will remove access to your information for the following account:
						</Box>
						<Center>
							<Box
								mb={4}
								fontWeight={700}>
								<chakra.span fontWeight={700}>{deleteAuthorizedProperty.emailAddress}</chakra.span>
							</Box>
						</Center>

						<Box mb={4}>Are you sure you want to remove access to your information on MyProperty?</Box>
					</ModalBody>
					<ModalFooter className={"modal-footer"}>
						<Flex
							direction={["column", "column", "row"]}
							w={["100%", "100%", ""]}
							justifyContent={"flex-end"}>
							<Button
								colorScheme={"red"}
								minW={["100%", "100%", 100]}
								//size={"sm"}
								onClick={(e) => handleDeleteAuthorizedProperty(e)}>
								YES, REMOVE
							</Button>
							<Button
								colorScheme='grey'
								variant={"outline"}
								minW={["100%", "100%", 100]}
								ml={[0, 0, 2]}
								mt={[2, 2, 0]}
								onClick={() => handleCancelDeleteAuthorizedProperty()}>
								Cancel
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>

			<Modal
				closeOnOverlayClick={false}
				isOpen={isOpenRenewalNotAllowed}
				onClose={onCloseRenewalNotAllowed}>
				<ModalOverlay />
				<ModalContent maxW={"37rem"}>
					<ModalHeader className={"modal-header"}>Shared Information Renewal</ModalHeader>
					<ModalCloseButton className='modal-close-button'>
						<FontAwesomeIcon
							size={"2xl"}
							icon='fa-solid fa-xmark'
						/>
					</ModalCloseButton>
					<ModalBody>
						<Box
							mt={2}
							mb={2}>
							{shareYourInfoDetails.shareYourInfoCannotRenew}
						</Box>
					</ModalBody>
					<ModalFooter className={"modal-footer"}></ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
export default ShareYourInformation;
